.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1280px;
  }
}
.container-xl, .container-lg, .container-md, .container-sm, .container {
  max-width: 1280px;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333333333%;
          flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.6666666667%;
          flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333333%;
          flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66666667%;
          flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333333%;
          flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66666667%;
          flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333333%;
          flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66666667%;
          flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333333%;
          flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66666667%;
          flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
}

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
}

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5;
}

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6;
}

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7;
}

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8;
}

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9;
}

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10;
}

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11;
}

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333333%;
            flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66666667%;
            flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333333%;
            flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66666667%;
            flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333333%;
            flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66666667%;
            flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333333%;
            flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66666667%;
            flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333333%;
            flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66666667%;
            flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333333%;
            flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66666667%;
            flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333333%;
            flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66666667%;
            flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333333%;
            flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66666667%;
            flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333333%;
            flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66666667%;
            flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333333%;
            flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66666667%;
            flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333333%;
            flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66666667%;
            flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333333%;
            flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66666667%;
            flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666666667%;
            flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333333%;
            flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66666667%;
            flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333333%;
            flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66666667%;
            flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333333%;
            flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66666667%;
            flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333333%;
            flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66666667%;
            flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.copyable {
  cursor: url(/_design/default/icons/cursor-copy.png) 2 2, copy;
  -webkit-user-select: all;
     -moz-user-select: all;
      -ms-user-select: all;
          user-select: all;
}

.copyable-alert-box {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 10000;
}

.copyable-alert-box:empty {
  display: none;
}

.alert-dismissible {
  word-break: break-all;
}
.alert-dismissible .close {
  cursor: pointer;
  background: transparent;
  border: none;
  line-height: 1;
  padding: 0 15px;
  height: 100%;
  font-size: 0;
}
.alert-dismissible .close:after {
  content: "\f00d";
  font-family: "Font Awesome 5 Pro";
  font-size: 0.875rem;
  font-weight: bold;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 100%;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

::-moz-selection {
  background: #E31937;
  color: #fff;
}

::selection {
  background: #E31937;
  color: #fff;
}

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  min-height: 100vh;
}
.page > #e-content {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 100%;
          flex: 0 1 100%;
  min-height: unset !important;
}
.page > #footer {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75px;
          flex: 0 0 75px;
}

#body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.7;
  color: #78777B;
  padding-bottom: 0;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 1rem 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #E31937;
}
h1 + h1,
h1 + h2,
h1 + h3,
h1 + h4,
h1 + h5,
h1 + h6,
h1 + p, h2 + h1,
h2 + h2,
h2 + h3,
h2 + h4,
h2 + h5,
h2 + h6,
h2 + p, h3 + h1,
h3 + h2,
h3 + h3,
h3 + h4,
h3 + h5,
h3 + h6,
h3 + p, h4 + h1,
h4 + h2,
h4 + h3,
h4 + h4,
h4 + h5,
h4 + h6,
h4 + p, h5 + h1,
h5 + h2,
h5 + h3,
h5 + h4,
h5 + h5,
h5 + h6,
h5 + p, h6 + h1,
h6 + h2,
h6 + h3,
h6 + h4,
h6 + h5,
h6 + h6,
h6 + p {
  margin-top: -1rem;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
}
@media (min-width: 992px) {
  h1 {
    font-size: 3.25rem;
  }
}

h2 {
  font-size: 2.125rem;
  font-weight: 600;
}
@media (min-width: 992px) {
  h2 {
    font-size: 2.5rem;
  }
}

h3 {
  font-size: 1.75rem;
  font-weight: 600;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  h3 {
    font-size: 2rem;
  }
}

h4 {
  font-size: 1.25rem;
  font-weight: 400;
}
@media (min-width: 992px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.125rem;
  font-weight: 600;
}

h6 {
  font-size: 1rem;
  font-weight: 600;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6,
.details h1,
.details h2,
.details h3,
.details h4,
.details h5,
.details h6 {
  font-size: 1.5rem;
}

a[href^=tel],
a[href^=mailto] {
  color: #E31937;
  text-decoration: none;
}

a[href^=mailto] {
  word-break: break-all;
}

ul:not(.menu),
ol:not(.menu) {
  margin: 20px 0;
  padding: 0 20px;
  line-height: 1.6;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.line hr {
  margin: 15px -15px;
}

.button {
  text-decoration: none;
  margin-bottom: 1rem;
  text-transform: uppercase;
  display: inline-block;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: #E31937;
  border: 1px solid #E31937;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}
.button:hover {
  color: #fff;
  background-color: #E31937;
  border-color: #E31937;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 767px) {
  .row .col-* {
    -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 100% !important;
            flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

#eyebrow-menu {
  height: 30px;
  background: #E31937;
  padding: 0;
  width: 100%;
  z-index: 5;
}
@media (min-width: 1280px) {
  #eyebrow-menu {
    padding: 0 15px;
  }
}
#eyebrow-menu .eye-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  height: 100%;
  text-align: right;
}
@media (min-width: 1280px) {
  #eyebrow-menu .eye-menu {
    padding: 0 25px 0 0;
  }
}
#eyebrow-menu .eye-menu li {
  display: inline-block;
}
#eyebrow-menu .eye-menuitemlabel {
  color: #fff;
  font-size: 0.9rem;
  line-height: 2.125;
  text-decoration: none;
  padding: 6px 20px;
}
#eyebrow-menu .eye-menuitemlabel:hover {
  background-color: #b5142c;
}
#eyebrow-menu .eye-menuitemlabel:active {
  background-color: #b5142c;
}

#header {
  position: relative;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  max-width: 100vw;
  height: auto !important;
  background: #fff;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  z-index: 15;
}
@media (min-width: 1280px) {
  #header {
    margin-bottom: 0;
  }
}
#header > .box-wc {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  text-align: left;
  z-index: 10;
}
#header p {
  margin: 0;
}
#header #main-menu {
  margin: 0;
  padding: 0 40px;
}
#header ul.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}
@media (max-width: 1279px) {
  #header ul.menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 85%;
    height: 100vh;
    max-width: 320px;
    padding-top: 120px;
    background: white;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    background: rgba(255, 255, 255, 0.85);
    text-align: left;
    z-index: 11;
  }
  #header ul.menu.open {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@media (min-width: 1280px) {
  #header ul.menu {
    position: static;
    width: 100%;
    height: 100%;
    max-width: none;
    padding: 0;
    -webkit-transform: none;
            transform: none;
    background: transparent;
  }
}
@media (min-width: 1280px) {
  #header ul.menu .mi {
    display: inline-block;
  }
}
#header ul.menu .mi .menuitemlabel {
  display: block;
  position: relative;
  color: #78777B;
  text-decoration: none;
  font-size: 1.025rem;
  font-weight: 600;
  padding: 15px;
  -webkit-transition: background 0.25s ease;
  transition: background 0.25s ease;
}
@media (min-width: 1280px) {
  #header ul.menu .mi .menuitemlabel {
    padding: 25px 20px;
    margin: 0;
    line-height: 3;
  }
  #header ul.menu .mi .menuitemlabel:after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #E31937;
    -webkit-transition: -webkit-transform 0.25s ease;
    transition: -webkit-transform 0.25s ease;
    transition: transform 0.25s ease;
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  }
  #header ul.menu .mi .menuitemlabel:hover:after {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
}
#header ul.menu .mi.mi-active .menuitemlabel {
  background: rgba(255, 255, 255, 0.04);
  color: #E31937;
}
@media (min-width: 1280px) {
  #header ul.menu .mi.mi-active .menuitemlabel {
    background: #fff;
  }
  #header ul.menu .mi.mi-active .menuitemlabel:after {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  #header ul.menu .mi.mi-active .menuitemlabel:hover {
    background-color: #fff;
  }
}
#header ul.menu .mi:hover .menuitemlabel {
  background: rgba(255, 255, 255, 0.06);
}
@media (min-width: 1280px) {
  #header ul.menu .mi:hover .menuitemlabel {
    background: transparent;
  }
}

#header-logo {
  position: relative;
  font-size: 0;
  margin: 0;
  padding: 0 40px;
  height: 80px;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 calc(100vw - 80px);
          flex: 0 1 calc(100vw - 80px);
  z-index: 12;
}
@media (min-width: 1280px) {
  #header-logo {
    height: 100px;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 585px;
            flex: 0 1 585px;
  }
}
#header-logo p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 585px;
}
#header-logo img {
  max-height: 80px;
  max-width: 100%;
  height: auto;
  width: auto;
}
@media (min-width: 1280px) {
  #header-logo img {
    max-height: 100px;
  }
}

#main-menu-button {
  -webkit-appearance: none;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(calc(-50% + 15px));
          transform: translateY(calc(-50% + 15px));
  border: none;
  padding: 4px 2px 2px;
  z-index: 12;
}
@media (min-width: 1280px) {
  #main-menu-button {
    display: none;
  }
}
#main-menu-button .menu-bars {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  display: block;
  height: 20px;
}
#main-menu-button .menu-label {
  display: block;
  text-transform: uppercase;
}

.l-header {
  background: #fff;
  margin: 0;
}

.menu {
  background: none;
}

.mi .btn .mi-inactive .first .last li {
  position: absolute;
  top: 20px;
}

.menu-icon {
  position: relative;
  min-height: 18px;
  min-width: 22px;
  cursor: pointer;
  background: transparent;
  outline: none;
}
.menu-icon span.menu-bars > span {
  width: 22px;
  height: 3px;
  background: #b1b1b1;
  display: block;
  position: absolute;
  left: calc(50% - 11px);
  -webkit-transform-origin: right center;
          transform-origin: right center;
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
  outline: 1px solid transparent;
}
.menu-icon span.menu-bars > span:nth-child(2), .menu-icon span.menu-bars > span:nth-child(3) {
  -webkit-transform: translateY(7px) rotate(0) scaleX(1);
          transform: translateY(7px) rotate(0) scaleX(1);
}
.menu-icon span.menu-bars > span:last-child {
  -webkit-transform: translateY(14px) rotate(0) scaleX(1);
          transform: translateY(14px) rotate(0) scaleX(1);
}
.menu-icon:not(.no-touch):hover span.menu-bars > span:first-child {
  -webkit-transform: translate(1px, -1px) rotate(-45deg) scaleX(0.82);
          transform: translate(1px, -1px) rotate(-45deg) scaleX(0.82);
}
.menu-icon:not(.no-touch):hover span.menu-bars > span:nth-child(2) {
  -webkit-transform: translate(-10px, 12px) rotate(45deg) scaleX(0.82);
          transform: translate(-10px, 12px) rotate(45deg) scaleX(0.82);
}
.menu-icon:not(.no-touch):hover span.menu-bars > span:nth-child(3) {
  -webkit-transform: translate(1px, 7px) rotate(-45deg) scaleX(0.82);
          transform: translate(1px, 7px) rotate(-45deg) scaleX(0.82);
}
.menu-icon:not(.no-touch):hover span.menu-bars > span:last-child {
  -webkit-transform: translate(-10px, 20px) rotate(45deg) scaleX(0.82);
          transform: translate(-10px, 20px) rotate(45deg) scaleX(0.82);
}
.menu-icon.open span.menu-bars > span:first-child, .menu-icon.open span.menu-bars > span:nth-child(3) {
  -webkit-transform: translate(-3px, 15.5px) rotate(45deg);
          transform: translate(-3px, 15.5px) rotate(45deg);
}
.menu-icon.open span.menu-bars > span:nth-child(2), .menu-icon.open span.menu-bars > span:last-child {
  -webkit-transform: translate(-3px, 0) rotate(-45deg);
          transform: translate(-3px, 0) rotate(-45deg);
}
.menu-icon.open:not(.no-touch):hover span.menu-bars > span:first-child {
  -webkit-transform: translate(1px, 10px) rotate(45deg) scaleX(0.82);
          transform: translate(1px, 10px) rotate(45deg) scaleX(0.82);
}
.menu-icon.open:not(.no-touch):hover span.menu-bars > span:nth-child(2) {
  -webkit-transform: translate(-10px, -3px) rotate(-45deg) scaleX(0.82);
          transform: translate(-10px, -3px) rotate(-45deg) scaleX(0.82);
}
.menu-icon.open:not(.no-touch):hover span.menu-bars > span:nth-child(3) {
  -webkit-transform: translate(1px, 18px) rotate(45deg) scaleX(0.82);
          transform: translate(1px, 18px) rotate(45deg) scaleX(0.82);
}
.menu-icon.open:not(.no-touch):hover span.menu-bars > span:last-child {
  -webkit-transform: translate(-10px, 5px) rotate(-45deg) scaleX(0.82);
          transform: translate(-10px, 5px) rotate(-45deg) scaleX(0.82);
}
.menu-icon.horizontal:not(.no-touch):hover span.menu-bars > span:first-child {
  -webkit-transform: translate(0px, 8px) rotate(45deg) scaleX(0.82);
          transform: translate(0px, 8px) rotate(45deg) scaleX(0.82);
}
.menu-icon.horizontal:not(.no-touch):hover span.menu-bars > span:last-child {
  -webkit-transform: translate(0px, 6px) rotate(-45deg) scaleX(0.82);
          transform: translate(0px, 6px) rotate(-45deg) scaleX(0.82);
}
.menu-icon.horizontal:not(.no-touch):hover span.menu-bars > span:nth-child(2) {
  -webkit-transform: translate(-8px, 8px) rotate(45deg) scaleX(0.82);
          transform: translate(-8px, 8px) rotate(45deg) scaleX(0.82);
}
.menu-icon.horizontal:not(.no-touch):hover span.menu-bars > span:nth-child(3) {
  -webkit-transform: translate(-8px, 6px) rotate(-45deg) scaleX(0.82);
          transform: translate(-8px, 6px) rotate(-45deg) scaleX(0.82);
}
.menu-icon.horizontal.open span.menu-bars > span:last-child, .menu-icon.horizontal.open span.menu-bars > span:nth-child(3) {
  -webkit-transform: translate(-3px, 15.5px) rotate(45deg);
          transform: translate(-3px, 15.5px) rotate(45deg);
}
.menu-icon.horizontal.open span.menu-bars > span:nth-child(2), .menu-icon.horizontal.open span.menu-bars > span:first-child {
  -webkit-transform: translate(-3px, 0) rotate(-45deg);
          transform: translate(-3px, 0) rotate(-45deg);
}
.menu-icon.horizontal.open:not(.no-touch):hover span.menu-bars > span:first-child {
  -webkit-transform: translate(-8px, -5px) rotate(-45deg) scaleX(0.82);
          transform: translate(-8px, -5px) rotate(-45deg) scaleX(0.82);
}
.menu-icon.horizontal.open:not(.no-touch):hover span.menu-bars > span:last-child {
  -webkit-transform: translate(-8px, 19px) rotate(45deg) scaleX(0.82);
          transform: translate(-8px, 19px) rotate(45deg) scaleX(0.82);
}
.menu-icon.horizontal.open:not(.no-touch):hover span.menu-bars > span:nth-child(2) {
  -webkit-transform: translate(0, -5px) rotate(-45deg) scaleX(0.82);
          transform: translate(0, -5px) rotate(-45deg) scaleX(0.82);
}
.menu-icon.horizontal.open:not(.no-touch):hover span.menu-bars > span:nth-child(3) {
  -webkit-transform: translate(0, 19px) rotate(45deg) scaleX(0.82);
          transform: translate(0, 19px) rotate(45deg) scaleX(0.82);
}

.page_banner {
  margin-top: 0;
}
.page_banner .banner-img {
  background-size: cover;
  height: 150px;
  overflow: hidden;
  position: relative;
  border-bottom: 3px solid #E4E6EB;
}
.page_banner .banner-title {
  width: 80%;
  max-width: 1280px;
  margin: auto;
  height: 150px;
  line-height: 135px;
}
.page_banner .banner-title h3 {
  vertical-align: middle;
  display: inline;
  color: #fff;
  font-size: 3rem;
  font-weight: 600;
  text-transform: uppercase;
}

#footer {
  margin-top: 0 !important;
  height: auto !important;
}

#copyright-footer {
  text-align: center;
  font-size: 0.9rem;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #fff;
  background: #E31937;
}
#copyright-footer .text {
  margin: 0 auto;
}
#copyright-footer > .box-wc {
  padding: 20px;
}
#copyright-footer p {
  margin: 0;
  font-size: 0.9rem;
}
#copyright-footer p a {
  color: #fff;
}
#copyright-footer .footer-nav {
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
}
#copyright-footer .footer-nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: 500px;
  max-width: 100%;
  margin: 10px auto;
}
#copyright-footer .footer-nav li {
  list-style: none;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  font-size: 0.9rem;
  padding: 1px;
}
#copyright-footer .footer-nav li a {
  text-decoration: none;
  color: #fff;
}
#copyright-footer .footer-nav li a:hover {
  text-decoration: underline;
}
#copyright-footer .footer-nav hr {
  border: 0;
  height: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin: 10px 0;
}

.alert {
  display: none;
  position: fixed;
  z-index: 20;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 10px;
  width: 80%;
  overflow: auto;
  font-size: 1rem;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-animation-name: animatetop;
          animation-name: animatetop;
  -webkit-animation-duration: 0.6s;
          animation-duration: 0.6s;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #c3e6cb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}
.card-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 0.5rem;
}
.card-title {
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0.75rem;
}
.card-subtitle {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
}
.card-text:not(.card-subtitle) {
  font-size: 0.85rem;
  overflow-wrap: normal;
  margin: 0;
}
.card .image,
.card .image-placeholder {
  position: relative;
  width: 100%;
  margin: 0;
  border-radius: 100%;
  overflow: hidden;
}
.card .image:before,
.card .image-placeholder:before {
  content: "";
  padding-top: 100%;
}
.card .image img {
  min-width: 100%;
  min-height: 100%;
  height: auto;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.card .image-placeholder {
  background-color: #E31937;
}
.card .image-placeholder:after {
  content: attr(data-initials);
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 2rem;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.card.vertical {
  min-width: 150px;
  margin: 0 auto 2rem;
}
.card.vertical .card-body {
  min-width: 150px;
  text-align: center;
}
.card.vertical .card-body .image,
.card.vertical .card-body .image-placeholder {
  height: 120px;
  width: 120px;
  margin: 10px auto;
}
.card.vertical .card-body .image-placeholder:after {
  font-size: 3.3rem;
  line-height: 1;
}
.card.vertical .card-body .card-title {
  color: #E31937;
  font-size: 1.25rem;
}
.card.horizontal {
  margin-bottom: 1rem;
}
.card.horizontal .card-header {
  margin-bottom: 5px;
}
.card.horizontal .card-header h6 {
  margin: 0;
}
.card.horizontal .card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}
.card.horizontal .card-body .card-title {
  margin-bottom: 0;
}
.card.horizontal .card-body .image {
  width: 70px;
  height: 70px;
  margin-right: 10px;
}

img {
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
}

.image-square {
  position: relative;
  overflow: hidden;
  padding: 0;
}
.image-square:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.image-square img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.image-banner img {
  width: 100%;
  height: auto;
}
.image-shadowed {
  -webkit-box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.2), 0 0.6rem 2rem 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 0.4rem 0.8rem 0 rgba(0, 0, 0, 0.2), 0 0.6rem 2rem 0 rgba(0, 0, 0, 0.19);
}

.container .heading {
  margin: 40px 0;
}
.container .heading h3 {
  display: block;
}